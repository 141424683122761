/*
 This file is part of GNU Taler
 (C) 2022 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import { buildStorageKey, useLocalStorage } from "@gnu-taler/web-util/browser";
import {
  Codec,
  buildCodecForObject,
  codecForBoolean,
  codecForConstString,
  codecForEither,
  codecForString,
} from "@gnu-taler/taler-util";

export interface Settings {
  advanceOrderMode: boolean;
  dateFormat: "ymd" | "dmy" | "mdy";
}

const defaultSettings: Settings = {
  advanceOrderMode: false,
  dateFormat: "ymd",
}

export const codecForSettings = (): Codec<Settings> =>
  buildCodecForObject<Settings>()
    .property("advanceOrderMode", codecForBoolean())
    .property("dateFormat", codecForEither(
      codecForConstString("ymd"),
      codecForConstString("dmy"),
      codecForConstString("mdy"),
    ))
    .build("Settings");

const SETTINGS_KEY = buildStorageKey("merchant-settings", codecForSettings());

export function useSettings(): [
  Readonly<Settings>,
  (s: Settings) => void,
] {
  const { value, update } = useLocalStorage(SETTINGS_KEY, defaultSettings);

  // const parsed: Settings = value ?? defaultSettings;
  // function updateField<T extends keyof Settings>(k: T, v: Settings[T]) {
  //   const next = { ...parsed, [k]: v }
  //   update(next);
  // }
  return [value, update];
}

export function dateFormatForSettings(s: Settings): string {
  switch (s.dateFormat) {
    case "ymd": return "yyyy/MM/dd"
    case "dmy": return "dd/MM/yyyy"
    case "mdy": return "MM/dd/yyyy"
  }
}

export function datetimeFormatForSettings(s: Settings): string {
  return dateFormatForSettings(s) + " HH:mm:ss"
}