/*
 This file is part of GNU Taler
 (C) 2021-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { HttpStatusCode } from "@gnu-taler/taler-util";
import {
  ErrorType,
  HttpError,
  useTranslationContext,
} from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { useState } from "preact/hooks";
import { Loading } from "../../../../components/exception/loading.js";
import { NotificationCard } from "../../../../components/menu/index.js";
import { MerchantBackend } from "../../../../declaration.js";
import { useInstanceOtpDevices, useOtpDeviceAPI } from "../../../../hooks/otp.js";
import { Notification } from "../../../../utils/types.js";
import { ListPage } from "./ListPage.js";

interface Props {
  onUnauthorized: () => VNode;
  onLoadError: (error: HttpError<MerchantBackend.ErrorDetail>) => VNode;
  onNotFound: () => VNode;
  onCreate: () => void;
  onSelect: (id: string) => void;
}

export default function ListOtpDevices({
  onUnauthorized,
  onLoadError,
  onCreate,
  onSelect,
  onNotFound,
}: Props): VNode {
  const [position, setPosition] = useState<string | undefined>(undefined);
  const { i18n } = useTranslationContext();
  const [notif, setNotif] = useState<Notification | undefined>(undefined);
  const { deleteOtpDevice } = useOtpDeviceAPI();
  const result = useInstanceOtpDevices({ position }, (id) => setPosition(id));

  if (result.loading) return <Loading />;
  if (!result.ok) {
    if (
      result.type === ErrorType.CLIENT &&
      result.status === HttpStatusCode.Unauthorized
    )
      return onUnauthorized();
    if (
      result.type === ErrorType.CLIENT &&
      result.status === HttpStatusCode.NotFound
    )
      return onNotFound();
    return onLoadError(result);
  }

  return (
    <Fragment>
      <NotificationCard notification={notif} />

      <ListPage
        devices={result.data.otp_devices}
        onLoadMoreBefore={
          result.isReachingStart ? result.loadMorePrev : undefined
        }
        onLoadMoreAfter={result.isReachingEnd ? result.loadMore : undefined}
        onCreate={onCreate}
        onSelect={(e) => {
          onSelect(e.otp_device_id);
        }}
        onDelete={(e: MerchantBackend.OTP.OtpDeviceEntry) =>
          deleteOtpDevice(e.otp_device_id)
            .then(() =>
              setNotif({
                message: i18n.str`validator delete successfully`,
                type: "SUCCESS",
              }),
            )
            .catch((error) =>
              setNotif({
                message: i18n.str`could not delete the validator`,
                type: "ERROR",
                description: error.message,
              }),
            )
        }
      />
    </Fragment>
  );
}
