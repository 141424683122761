import { AmountJson, Amounts, TranslatedString } from "@gnu-taler/taler-util";
import { VNode, h } from "preact";
import { UIFormProps } from "./FormProvider.js";
import { InputLine } from "./InputLine.js";
import { useField } from "./useField.js";

export function InputAmount<T extends object, K extends keyof T>(
  props: { currency?: string } & UIFormProps<T, K>,
): VNode {
  const { value } = useField<T, K>(props.name);
  const currency =
    !value || !(value as any).currency
      ? props.currency
      : (value as any).currency;
  return (
    <InputLine<T, K>
      type="text"
      before={{
        type: "text",
        text: currency as TranslatedString,
      }}
      converter={{
        //@ts-ignore
        fromStringUI: (v): AmountJson => {

          return Amounts.parse(`${currency}:${v}`) ?? Amounts.zeroOfCurrency(currency);
        },
        //@ts-ignore
        toStringUI: (v: AmountJson) => {
          return v === undefined ? "" : Amounts.stringifyValue(v);
        },
      }}
      {...props}
    />
  );
}
