/*
 This file is part of GNU Taler
 (C) 2021-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, h, VNode } from "preact";
import { useState } from "preact/hooks";
import { NotificationCard } from "../../../../components/menu/index.js";
import { MerchantBackend } from "../../../../declaration.js";
import { useBankAccountAPI } from "../../../../hooks/bank.js";
import { Notification } from "../../../../utils/types.js";
import { CreatePage } from "./CreatePage.js";

export type Entity = MerchantBackend.BankAccounts.AccountAddDetails;
interface Props {
  onBack?: () => void;
  onConfirm: () => void;
}

export default function CreateValidator({ onConfirm, onBack }: Props): VNode {
  const { createBankAccount } = useBankAccountAPI();
  const [notif, setNotif] = useState<Notification | undefined>(undefined);
  const { i18n } = useTranslationContext();

  return (
    <>
      <NotificationCard notification={notif} />
      <CreatePage
        onBack={onBack}
        onCreate={(request: Entity) => {
          return createBankAccount(request)
            .then((d) => {
              onConfirm()
            })
            .catch((error) => {
              setNotif({
                message: i18n.str`could not create account`,
                type: "ERROR",
                description: error.message,
              });
            });
        }}
      />
    </>
  );
}
