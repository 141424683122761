/*
 This file is part of GNU Taler
 (C) 2021-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */
import { HttpStatusCode } from "@gnu-taler/taler-util";
import {
  ErrorType,
  HttpError,
  HttpResponse,
  useTranslationContext,
} from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { useState } from "preact/hooks";
import { Loading } from "../../../components/exception/loading.js";
import { NotificationCard } from "../../../components/menu/index.js";
import { useInstanceContext } from "../../../context/instance.js";
import { AccessToken, MerchantBackend } from "../../../declaration.js";
import {
  useInstanceAPI,
  useInstanceDetails,
  useManagedInstanceDetails,
  useManagementAPI,
} from "../../../hooks/instance.js";
import { Notification } from "../../../utils/types.js";
import { UpdatePage } from "./UpdatePage.js";

export interface Props {
  onBack: () => void;
  onConfirm: () => void;

  onUnauthorized: () => VNode;
  onNotFound: () => VNode;
  onLoadError: (e: HttpError<MerchantBackend.ErrorDetail>) => VNode;
  onUpdateError: (e: HttpError<MerchantBackend.ErrorDetail>) => void;
}

export default function Update(props: Props): VNode {
  const { updateInstance } = useInstanceAPI();
  const result = useInstanceDetails();
  return CommonUpdate(props, result, updateInstance, );
}

export function AdminUpdate(props: Props & { instanceId: string }): VNode {
  const { updateInstance } = useManagementAPI(
    props.instanceId,
  );
  const result = useManagedInstanceDetails(props.instanceId);
  return CommonUpdate(props, result, updateInstance, );
}

function CommonUpdate(
  {
    onBack,
    onConfirm,
    onLoadError,
    onNotFound,
    onUpdateError,
    onUnauthorized,
  }: Props,
  result: HttpResponse<
    MerchantBackend.Instances.QueryInstancesResponse,
    MerchantBackend.ErrorDetail
  >,
  updateInstance: any,
): VNode {
  const [notif, setNotif] = useState<Notification | undefined>(undefined);
  const { i18n } = useTranslationContext();

  if (result.loading) return <Loading />;
  if (!result.ok) {
    if (
      result.type === ErrorType.CLIENT &&
      result.status === HttpStatusCode.Unauthorized
    )
      return onUnauthorized();
    if (
      result.type === ErrorType.CLIENT &&
      result.status === HttpStatusCode.NotFound
    )
      return onNotFound();
    return onLoadError(result);
  }

  return (
    <Fragment>
      <NotificationCard notification={notif} />
      <UpdatePage
        onBack={onBack}
        isLoading={false}
        selected={result.data}
        onUpdate={(
          d: MerchantBackend.Instances.InstanceReconfigurationMessage,
        ): Promise<void> => {
          return updateInstance(d)
            .then(onConfirm)
            .catch((error: Error) =>
              setNotif({
                message: i18n.str`Failed to create instance`,
                type: "ERROR",
                description: error.message,
              }),
            );
        }}
      />
    </Fragment>
  );
}
