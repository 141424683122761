/*
 This file is part of GNU Taler
 (C) 2021-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { h, VNode } from "preact";
import { useState } from "preact/hooks";
import { AsyncButton } from "../../../../components/exception/AsyncButton.js";
import {
  FormErrors,
  FormProvider,
} from "../../../../components/form/FormProvider.js";
import { Input } from "../../../../components/form/Input.js";
import { InputCurrency } from "../../../../components/form/InputCurrency.js";
import { MerchantBackend } from "../../../../declaration.js";

type Entity = MerchantBackend.Template.UsingTemplateDetails;

interface Props {
  id: string;
  template: MerchantBackend.Template.TemplateDetails;
  onCreateOrder: (d: Entity) => Promise<void>;
  onBack?: () => void;
}

export function UsePage({ id, template, onCreateOrder, onBack }: Props): VNode {
  const { i18n } = useTranslationContext();

  const [state, setState] = useState<Partial<Entity>>({
    amount: template.template_contract.amount,
    summary: template.template_contract.summary,
  });

  const errors: FormErrors<Entity> = {
    amount:
      !template.template_contract.amount && !state.amount
        ? i18n.str`Amount is required`
        : undefined,
    summary:
      !template.template_contract.summary && !state.summary
        ? i18n.str`Order summary is required`
        : undefined,
  };

  const hasErrors = Object.keys(errors).some(
    (k) => (errors as any)[k] !== undefined,
  );

  const submitForm = () => {
    if (hasErrors) return Promise.reject();
    if (template.template_contract.amount) {
      delete state.amount;
    }
    if (template.template_contract.summary) {
      delete state.summary;
    }
    return onCreateOrder(state as any);
  };

  return (
    <div>
      <section class="section">
        <section class="hero is-hero-bar">
          <div class="hero-body">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <span class="is-size-4">
                    <i18n.Translate>New order for template</i18n.Translate>:{" "}
                    <b>{id}</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section class="section is-main-section">
        <div class="columns">
          <div class="column" />
          <div class="column is-four-fifths">
            <FormProvider
              object={state}
              valueHandler={setState}
              errors={errors}
            >
              <InputCurrency<Entity>
                name="amount"
                label={i18n.str`Amount`}
                readonly={!!template.template_contract.amount}
                tooltip={i18n.str`Amount of the order`}
              />
              <Input<Entity>
                name="summary"
                inputType="multiline"
                label={i18n.str`Order summary`}
                readonly={!!template.template_contract.summary}
                tooltip={i18n.str`Title of the order to be shown to the customer`}
              />
            </FormProvider>

            <div class="buttons is-right mt-5">
              {onBack && (
                <button class="button" onClick={onBack}>
                  <i18n.Translate>Cancel</i18n.Translate>
                </button>
              )}
              <AsyncButton
                disabled={hasErrors}
                data-tooltip={
                  hasErrors
                    ? i18n.str`Need to complete marked fields`
                    : "confirm operation"
                }
                onClick={submitForm}
              >
                <i18n.Translate>Confirm</i18n.Translate>
              </AsyncButton>
            </div>
          </div>
          <div class="column" />
        </div>
      </section>
    </div>
  );
}
