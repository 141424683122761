/*
 This file is part of GNU Taler
 (C) 2021-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */
/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, h, VNode } from "preact";
import { useState } from "preact/hooks";
import { NotificationCard } from "../../../components/menu/index.js";
import { AccessToken, MerchantBackend } from "../../../declaration.js";
import { useAdminAPI, useInstanceAPI } from "../../../hooks/instance.js";
import { Notification } from "../../../utils/types.js";
import { CreatePage } from "./CreatePage.js";
import { useCredentialsChecker } from "../../../hooks/backend.js";
import { useBackendContext } from "../../../context/backend.js";

interface Props {
  onBack?: () => void;
  onConfirm: () => void;
  forceId?: string;
}
export type Entity = MerchantBackend.Instances.InstanceConfigurationMessage;

export default function Create({ onBack, onConfirm, forceId }: Props): VNode {
  const { createInstance } = useAdminAPI();
  const [notif, setNotif] = useState<Notification | undefined>(undefined);
  const { i18n } = useTranslationContext();
  const { requestNewLoginToken } = useCredentialsChecker()
  const { url: backendURL, updateToken } = useBackendContext()

  return (
    <Fragment>
      <NotificationCard notification={notif} />

      <CreatePage
        onBack={onBack}
        forceId={forceId}
        onCreate={async (
          d: MerchantBackend.Instances.InstanceConfigurationMessage,
        ) => {
          try {
            await createInstance(d)
            if (d.auth.token) {
              const resp = await requestNewLoginToken(backendURL, d.auth.token as AccessToken)
              if (resp.valid) {
                const { token, expiration } = resp
                updateToken({ token, expiration });
              } else {
                updateToken(undefined)
              }
            }
            onConfirm();
          } catch (ex) {
            if (ex instanceof Error) {
              setNotif({
                message: i18n.str`Failed to create instance`,
                type: "ERROR",
                description: ex.message,
              });
            } else {
              console.error(ex)
            }
          }
        }}
      />
    </Fragment>
  );
}
