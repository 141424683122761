/*
 This file is part of GNU Taler
 (C) 2021-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, h, VNode } from "preact";
import { StateUpdater, useState } from "preact/hooks";
import { MerchantBackend } from "../../../../declaration.js";
import { parsePaytoUri, PaytoType, PaytoUri, PaytoUriBitcoin, PaytoUriIBAN, PaytoUriTalerBank, PaytoUriUnknown } from "@gnu-taler/taler-util";

type Entity = MerchantBackend.BankAccounts.BankAccountEntry;

interface Props {
  accounts: Entity[];
  onDelete: (e: Entity) => void;
  onSelect: (e: Entity) => void;
  onCreate: () => void;
  onLoadMoreBefore?: () => void;
  hasMoreBefore?: boolean;
  hasMoreAfter?: boolean;
  onLoadMoreAfter?: () => void;
}

export function CardTable({
  accounts,
  onCreate,
  onDelete,
  onSelect,
  onLoadMoreAfter,
  onLoadMoreBefore,
  hasMoreAfter,
  hasMoreBefore,
}: Props): VNode {
  const [rowSelection, rowSelectionHandler] = useState<string[]>([]);

  const { i18n } = useTranslationContext();

  return (
    <div class="card has-table">
      <header class="card-header">
        <p class="card-header-title">
          <span class="icon">
            <i class="mdi mdi-newspaper" />
          </span>
          <i18n.Translate>Bank accounts</i18n.Translate>
        </p>
        <div class="card-header-icon" aria-label="more options">
          <span
            class="has-tooltip-left"
            data-tooltip={i18n.str`add new accounts`}
          >
            <button class="button is-info" type="button" onClick={onCreate}>
              <span class="icon is-small">
                <i class="mdi mdi-plus mdi-36px" />
              </span>
            </button>
          </span>
        </div>
      </header>
      <div class="card-content">
        <div class="b-table has-pagination">
          <div class="table-wrapper has-mobile-cards">
            {accounts.length > 0 ? (
              <Table
                accounts={accounts}
                onDelete={onDelete}
                onSelect={onSelect}
                rowSelection={rowSelection}
                rowSelectionHandler={rowSelectionHandler}
                onLoadMoreAfter={onLoadMoreAfter}
                onLoadMoreBefore={onLoadMoreBefore}
                hasMoreAfter={hasMoreAfter}
                hasMoreBefore={hasMoreBefore}
              />
            ) : (
              <EmptyTable />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
interface TableProps {
  rowSelection: string[];
  accounts: Entity[];
  onDelete: (e: Entity) => void;
  onSelect: (e: Entity) => void;
  rowSelectionHandler: StateUpdater<string[]>;
  onLoadMoreBefore?: () => void;
  hasMoreBefore?: boolean;
  hasMoreAfter?: boolean;
  onLoadMoreAfter?: () => void;
}

function toggleSelected<T>(id: T): (prev: T[]) => T[] {
  return (prev: T[]): T[] =>
    prev.indexOf(id) == -1 ? [...prev, id] : prev.filter((e) => e != id);
}

function Table({
  accounts,
  onLoadMoreAfter,
  onDelete,
  onSelect,
  onLoadMoreBefore,
  hasMoreAfter,
  hasMoreBefore,
}: TableProps): VNode {
  const { i18n } = useTranslationContext();
  const emptyList: Record<PaytoType | "unknown", { parsed: PaytoUri, acc: Entity }[]> = { "bitcoin": [], "x-taler-bank": [], "iban": [], "unknown": [], }
  const accountsByType = accounts.reduce((prev, acc) => {
    const parsed = parsePaytoUri(acc.payto_uri)
    if (!parsed) return prev //skip
    if (parsed.targetType !== "bitcoin" && parsed.targetType !== "x-taler-bank" && parsed.targetType !== "iban") {
      prev["unknown"].push({ parsed, acc })
    } else {
      prev[parsed.targetType].push({ parsed, acc })
    }
    return prev
  }, emptyList)

  const bitcoinAccounts = accountsByType["bitcoin"]
  const talerbankAccounts = accountsByType["x-taler-bank"]
  const ibanAccounts = accountsByType["iban"]
  const unkownAccounts = accountsByType["unknown"]


  return (
    <Fragment>

      {bitcoinAccounts.length > 0 && <div class="table-container">
        <p class="card-header-title"><i18n.Translate>Bitcoin type accounts</i18n.Translate></p>
        <table class="table is-fullwidth is-striped is-hoverable is-fullwidth">
          <thead>
            <tr>
              <th>
                <i18n.Translate>Address</i18n.Translate>
              </th>
              <th>
                <i18n.Translate>Sewgit 1</i18n.Translate>
              </th>
              <th>
                <i18n.Translate>Sewgit 2</i18n.Translate>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {bitcoinAccounts.map(({ parsed, acc }, idx) => {
              const ac = parsed as PaytoUriBitcoin
              return (
                <tr key={idx}>
                  <td
                    onClick={(): void => onSelect(acc)}
                    style={{ cursor: "pointer" }}
                  >
                    {ac.targetPath}
                  </td>
                  <td
                    onClick={(): void => onSelect(acc)}
                    style={{ cursor: "pointer" }}
                  >
                    {ac.segwitAddrs[0]}
                  </td>
                  <td
                    onClick={(): void => onSelect(acc)}
                    style={{ cursor: "pointer" }}
                  >
                    {ac.segwitAddrs[1]}
                  </td>
                  <td class="is-actions-cell right-sticky">
                    <div class="buttons is-right">
                      <button
                        class="button is-danger is-small has-tooltip-left"
                        data-tooltip={i18n.str`delete selected accounts from the database`}
                        onClick={() => onDelete(acc)}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>}



      {talerbankAccounts.length > 0 && <div class="table-container">
        <p class="card-header-title"><i18n.Translate>Taler type accounts</i18n.Translate></p>
        <table class="table is-fullwidth is-striped is-hoverable is-fullwidth">
          <thead>
            <tr>
              <th>
                <i18n.Translate>Host</i18n.Translate>
              </th>
              <th>
                <i18n.Translate>Account name</i18n.Translate>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {talerbankAccounts.map(({ parsed, acc }, idx) => {
              const ac = parsed as PaytoUriTalerBank
              return (
                <tr key={idx}>
                  <td
                    onClick={(): void => onSelect(acc)}
                    style={{ cursor: "pointer" }}
                  >
                    {ac.host}
                  </td>
                  <td
                    onClick={(): void => onSelect(acc)}
                    style={{ cursor: "pointer" }}
                  >
                    {ac.account}
                  </td>
                  <td class="is-actions-cell right-sticky">
                    <div class="buttons is-right">
                      <button
                        class="button is-danger is-small has-tooltip-left"
                        data-tooltip={i18n.str`delete selected accounts from the database`}
                        onClick={() => onDelete(acc)}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>}

      {ibanAccounts.length > 0 && <div class="table-container">
        <p class="card-header-title"><i18n.Translate>IBAN type accounts</i18n.Translate></p>
        <table class="table is-fullwidth is-striped is-hoverable is-fullwidth">
          <thead>
            <tr>
              <th>
                <i18n.Translate>Account name</i18n.Translate>
              </th>
              <th>
                <i18n.Translate>IBAN</i18n.Translate>
              </th>
              <th>
                <i18n.Translate>BIC</i18n.Translate>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {ibanAccounts.map(({ parsed, acc }, idx) => {
              const ac = parsed as PaytoUriIBAN
              return (
                <tr key={idx}>
                  <td
                    onClick={(): void => onSelect(acc)}
                    style={{ cursor: "pointer" }}
                  >
                    {ac.params["receiver-name"]}
                  </td>
                  <td
                    onClick={(): void => onSelect(acc)}
                    style={{ cursor: "pointer" }}
                  >
                    {ac.iban}
                  </td>
                  <td
                    onClick={(): void => onSelect(acc)}
                    style={{ cursor: "pointer" }}
                  >
                    {ac.bic ?? ""}
                  </td>
                  <td class="is-actions-cell right-sticky">
                    <div class="buttons is-right">
                      <button
                        class="button is-danger is-small has-tooltip-left"
                        data-tooltip={i18n.str`delete selected accounts from the database`}
                        onClick={() => onDelete(acc)}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>}

      {unkownAccounts.length > 0 && <div class="table-container">
        <p class="card-header-title"><i18n.Translate>Other type accounts</i18n.Translate></p>
        <table class="table is-fullwidth is-striped is-hoverable is-fullwidth">
          <thead>
            <tr>
              <th>
                <i18n.Translate>Type</i18n.Translate>
              </th>
              <th>
                <i18n.Translate>Path</i18n.Translate>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {unkownAccounts.map(({ parsed, acc }, idx) => {
              const ac = parsed as PaytoUriUnknown
              return (
                <tr key={idx}>
                  <td
                    onClick={(): void => onSelect(acc)}
                    style={{ cursor: "pointer" }}
                  >
                    {ac.targetType}
                  </td>
                  <td
                    onClick={(): void => onSelect(acc)}
                    style={{ cursor: "pointer" }}
                  >
                    {ac.targetPath}
                  </td>
                  <td class="is-actions-cell right-sticky">
                    <div class="buttons is-right">
                      <button
                        class="button is-danger is-small has-tooltip-left"
                        data-tooltip={i18n.str`delete selected accounts from the database`}
                        onClick={() => onDelete(acc)}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>}
    </Fragment>

  );
}

function EmptyTable(): VNode {
  const { i18n } = useTranslationContext();
  return (
    <div class="content has-text-grey has-text-centered">
      <p>
        <span class="icon is-large">
          <i class="mdi mdi-emoticon-sad mdi-48px" />
        </span>
      </p>
      <p>
        <i18n.Translate>
          There is no accounts yet, add more pressing the + sign
        </i18n.Translate>
      </p>
    </div>
  );
}
