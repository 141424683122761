/*
 This file is part of GNU Taler
 (C) 2021-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { useMemoryStorage } from "@gnu-taler/web-util/browser";
import { createContext, h, VNode } from "preact";
import { useContext } from "preact/hooks";
import { LoginToken } from "../declaration.js";
import { useBackendDefaultToken, useBackendURL } from "../hooks/index.js";

interface BackendContextType {
  url: string,
  alreadyTriedLogin: boolean;
  token?: LoginToken;
  updateToken: (token: LoginToken | undefined) => void;
}

const BackendContext = createContext<BackendContextType>({
  url: "",
  alreadyTriedLogin: false,
  token: undefined,
  updateToken: () => null,
});

function useBackendContextState(
  defaultUrl?: string,
): BackendContextType {
  const [url] = useBackendURL(defaultUrl);
  const [token, updateToken] = useBackendDefaultToken();

  return {
    url,
    token,
    alreadyTriedLogin: token !== undefined,
    updateToken,
  };
}

export const BackendContextProvider = ({
  children,
  defaultUrl,
}: {
  children: any;
  defaultUrl?: string;
}): VNode => {
  const value = useBackendContextState(defaultUrl);

  return h(BackendContext.Provider, { value, children });
};

export const useBackendContext = (): BackendContextType =>
  useContext(BackendContext);
