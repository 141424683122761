/*
 This file is part of GNU Taler
 (C) 2021-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { ErrorType, HttpError } from "@gnu-taler/web-util/browser";
import { Fragment, h, VNode } from "preact";
import { useState } from "preact/hooks";
import { Loading } from "../../../../components/exception/loading.js";
import { NotificationCard } from "../../../../components/menu/index.js";
import { MerchantBackend } from "../../../../declaration.js";
import { useInstanceDetails } from "../../../../hooks/instance.js";
import { useOrderAPI } from "../../../../hooks/order.js";
import { useInstanceProducts } from "../../../../hooks/product.js";
import { Notification } from "../../../../utils/types.js";
import { CreatePage } from "./CreatePage.js";
import { HttpStatusCode } from "@gnu-taler/taler-util";

export type Entity = {
  request: MerchantBackend.Orders.PostOrderRequest;
  response: MerchantBackend.Orders.PostOrderResponse;
};
interface Props {
  onBack?: () => void;
  onConfirm: (id: string) => void;
  onUnauthorized: () => VNode;
  onNotFound: () => VNode;
  onLoadError: (error: HttpError<MerchantBackend.ErrorDetail>) => VNode;
}
export default function OrderCreate({
  onConfirm,
  onBack,
  onLoadError,
  onNotFound,
  onUnauthorized,
}: Props): VNode {
  const { createOrder } = useOrderAPI();
  const [notif, setNotif] = useState<Notification | undefined>(undefined);

  const detailsResult = useInstanceDetails();
  const inventoryResult = useInstanceProducts();

  if (detailsResult.loading) return <Loading />;
  if (inventoryResult.loading) return <Loading />;

  if (!detailsResult.ok) {
    if (
      detailsResult.type === ErrorType.CLIENT &&
      detailsResult.status === HttpStatusCode.Unauthorized
    )
      return onUnauthorized();
    if (
      detailsResult.type === ErrorType.CLIENT &&
      detailsResult.status === HttpStatusCode.NotFound
    )
      return onNotFound();
    return onLoadError(detailsResult);
  }

  if (!inventoryResult.ok) {
    if (
      inventoryResult.type === ErrorType.CLIENT &&
      inventoryResult.status === HttpStatusCode.Unauthorized
    )
      return onUnauthorized();
    if (
      inventoryResult.type === ErrorType.CLIENT &&
      inventoryResult.status === HttpStatusCode.NotFound
    )
      return onNotFound();
    return onLoadError(inventoryResult);
  }

  return (
    <Fragment>
      <NotificationCard notification={notif} />

      <CreatePage
        onBack={onBack}
        onCreate={(request: MerchantBackend.Orders.PostOrderRequest) => {
          createOrder(request)
            .then((r) => {
              return onConfirm(r.data.order_id)
            })
            .catch((error) => {
              setNotif({
                message: "could not create order",
                type: "ERROR",
                description: error.message,
              });
            });
        }}
        instanceConfig={detailsResult.data}
        instanceInventory={inventoryResult.data}
      />
    </Fragment>
  );
}
