/*
 This file is part of GNU Taler
 (C) 2021-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { stringifyPayTemplateUri } from "@gnu-taler/taler-util";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { VNode, h } from "preact";
import { useState } from "preact/hooks";
import { QR } from "../../../../components/exception/QR.js";
import {
  FormErrors,
  FormProvider,
} from "../../../../components/form/FormProvider.js";
import { Input } from "../../../../components/form/Input.js";
import { InputCurrency } from "../../../../components/form/InputCurrency.js";
import { useBackendContext } from "../../../../context/backend.js";
import { useConfigContext } from "../../../../context/config.js";
import { useInstanceContext } from "../../../../context/instance.js";
import { MerchantBackend } from "../../../../declaration.js";

type Entity = MerchantBackend.Template.UsingTemplateDetails;

interface Props {
  contract: MerchantBackend.Template.TemplateContractDetails;
  id: string;
  onBack?: () => void;
}

export function QrPage({ contract, id: templateId, onBack }: Props): VNode {
  const { i18n } = useTranslationContext();
  const { url: backendURL } = useBackendContext()
  const { id: instanceId } = useInstanceContext();
  const config = useConfigContext();

  const [state, setState] = useState<Partial<Entity>>({
    amount: contract.amount,
    summary: contract.summary,
  });

  const errors: FormErrors<Entity> = {};

  const fixedAmount = !!contract.amount;
  const fixedSummary = !!contract.summary;

  const templateParams: Record<string, string> = {}
  if (!fixedAmount) {
    if (state.amount) {
      templateParams.amount = state.amount
    } else {
      templateParams.amount = config.currency
    }
  }

  if (!fixedSummary) {
    templateParams.summary = state.summary ?? ""
  }

  const merchantBaseUrl = new URL(backendURL).href;

  const payTemplateUri = stringifyPayTemplateUri({
    merchantBaseUrl,
    templateId,
    templateParams
  })

  const issuer = encodeURIComponent(
    `${new URL(backendURL).host}/${instanceId}`,
  );

  return (
    <div>
      <section class="section is-main-section">
        <div class="columns">
          <div class="column" />
          <div class="column is-four-fifths">
            <p class="is-size-5 mt-5 mb-5">
              <i18n.Translate>
                Here you can specify a default value for fields that are not
                fixed. Default values can be edited by the customer before the
                payment.
              </i18n.Translate>
            </p>

            <p></p>
            <FormProvider
              object={state}
              valueHandler={setState}
              errors={errors}
            >
              <InputCurrency<Entity>
                name="amount"
                label={
                  fixedAmount
                    ? i18n.str`Fixed amount`
                    : i18n.str`Default amount`
                }
                readonly={fixedAmount}
                tooltip={i18n.str`Amount of the order`}
              />
              <Input<Entity>
                name="summary"
                inputType="multiline"
                readonly={fixedSummary}
                label={
                  fixedSummary
                    ? i18n.str`Fixed summary`
                    : i18n.str`Default summary`
                }
                tooltip={i18n.str`Title of the order to be shown to the customer`}
              />
            </FormProvider>

            <div class="buttons is-right mt-5">
              {onBack && (
                <button class="button" onClick={onBack}>
                  <i18n.Translate>Cancel</i18n.Translate>
                </button>
              )}
              <button
                class="button is-info"
                onClick={() => saveAsPDF(templateId)}
              >
                <i18n.Translate>Print</i18n.Translate>
              </button>
            </div>
          </div>
          <div class="column" />
        </div>
      </section>
      <section id="printThis">
        <QR text={payTemplateUri} />
        <pre style={{ textAlign: "center" }}>
          <a href={payTemplateUri}>{payTemplateUri}</a>
        </pre>
      </section>
    </div>
  );
}

function saveAsPDF(name: string): void {
  const printWindow = window.open("", "", "height=400,width=800");
  if (!printWindow) return;
  const divContents = document.getElementById("printThis");
  if (!divContents) return;
  printWindow.document.write(
    `<html><head><title>Order template for ${name}</title><style>`,
  );
  printWindow.document.write("</style></head><body>&nbsp;</body></html>");
  printWindow.document.close();
  printWindow.document.body.appendChild(divContents.cloneNode(true));
  printWindow.addEventListener("load", () => {
    printWindow.print();
    printWindow.close();
  });
}
