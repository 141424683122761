import { useLang, useTranslationContext } from "@gnu-taler/web-util/browser";
import { VNode, h } from "preact";
import { FormErrors, FormProvider } from "../../components/form/FormProvider.js";
import { InputSelector } from "../../components/form/InputSelector.js";
import { InputToggle } from "../../components/form/InputToggle.js";
import { LangSelector } from "../../components/menu/LangSelector.js";
import { Settings, useSettings } from "../../hooks/useSettings.js";

function getBrowserLang(): string | undefined {
  if (typeof window === "undefined") return undefined;
  if (window.navigator.languages) return window.navigator.languages[0];
  if (window.navigator.language) return window.navigator.language;
  return undefined;
}

export function Settings({ onClose }: { onClose?: () => void }): VNode {
  const { i18n } = useTranslationContext()
  const borwserLang = getBrowserLang()
  const { update } = useLang(undefined, {})

  const [value, updateValue] = useSettings()
  const errors: FormErrors<Settings> = {
  }

  function valueHandler(s: (d: Partial<Settings>) => Partial<Settings>): void {
    const next = s(value)
    const v: Settings = {
      advanceOrderMode: next.advanceOrderMode ?? false,
      dateFormat: next.dateFormat ?? "ymd"
    }
    updateValue(v)
  }

  return <div>
    <section class="section is-main-section">
      <div class="columns">
        <div class="column" />
        <div class="column is-four-fifths">
          <div>

            <FormProvider<Settings>
              name="settings"
              errors={errors}
              object={value}
              valueHandler={valueHandler}
            >
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">
                    <i18n.Translate>Language</i18n.Translate>
                    <span class="icon has-tooltip-right" data-tooltip={"Force language setting instance of taking the browser"}>
                      <i class="mdi mdi-information" />
                    </span>
                  </label>
                </div>
                <div class="field field-body has-addons is-flex-grow-3">
                  <LangSelector />
                  &nbsp;
                  {borwserLang !== undefined && <button
                    data-tooltip={i18n.str`generate random secret key`}
                    class="button is-info mr-2"
                    onClick={(e) => {
                      update(borwserLang.substring(0, 2))
                    }}
                  >
                    <i18n.Translate>Set default</i18n.Translate>
                  </button>}
                </div>
              </div>
              <InputToggle<Settings>
                label={i18n.str`Advance order creation`}
                tooltip={i18n.str`Shows more options in the order creation form`}
                name="advanceOrderMode"
              />
              <InputSelector<Settings>
                name="dateFormat"
                label={i18n.str`Date format`}
                expand={true}
                help={
                  value.dateFormat === "dmy" ? "31/12/2001" : value.dateFormat === "mdy" ? "12/31/2001" : value.dateFormat === "ymd" ? "2001/12/31" : ""
                }
                toStr={(e) => {
                  if (e === "ymd") return "year month day"
                  if (e === "mdy") return "month day year"
                  if (e === "dmy") return "day month year"
                  return "choose one"
                }}
                values={[
                  "ymd",
                  "mdy",
                  "dmy",
                ]}
                tooltip={i18n.str`how the date is going to be displayed`}
              />
            </FormProvider>
          </div>
        </div>
        <div class="column" />
      </div>
    </section >
    {onClose &&
      <section class="section is-main-section">
        <button
          class="button"
          onClick={onClose}
        >
          <i18n.Translate>Close</i18n.Translate>
        </button>
      </section>
    }
  </div >
}