/*
 This file is part of GNU Taler
 (C) 2021-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import {
  ErrorType,
  HttpError,
  useTranslationContext,
} from "@gnu-taler/web-util/browser";
import { Fragment, h, VNode } from "preact";
import { useState } from "preact/hooks";
import { Loading } from "../../../../components/exception/loading.js";
import { NotificationCard } from "../../../../components/menu/index.js";
import { MerchantBackend } from "../../../../declaration.js";
import {
  useTemplateAPI,
  useTemplateDetails,
} from "../../../../hooks/templates.js";
import { Notification } from "../../../../utils/types.js";
import { UsePage } from "./UsePage.js";
import { HttpStatusCode } from "@gnu-taler/taler-util";

export type Entity = MerchantBackend.Transfers.TransferInformation;
interface Props {
  onBack?: () => void;
  onOrderCreated: (id: string) => void;
  onUnauthorized: () => VNode;
  onNotFound: () => VNode;
  onLoadError: (e: HttpError<MerchantBackend.ErrorDetail>) => VNode;
  tid: string;
}

export default function TemplateUsePage({
  tid,
  onOrderCreated,
  onBack,
  onLoadError,
  onNotFound,
  onUnauthorized,
}: Props): VNode {
  const { createOrderFromTemplate } = useTemplateAPI();
  const result = useTemplateDetails(tid);
  const [notif, setNotif] = useState<Notification | undefined>(undefined);
  const { i18n } = useTranslationContext();

  if (result.loading) return <Loading />;
  if (!result.ok) {
    if (
      result.type === ErrorType.CLIENT &&
      result.status === HttpStatusCode.Unauthorized
    )
      return onUnauthorized();
    if (
      result.type === ErrorType.CLIENT &&
      result.status === HttpStatusCode.NotFound
    )
      return onNotFound();
    return onLoadError(result);
  }

  return (
    <>
      <NotificationCard notification={notif} />
      <UsePage
        template={result.data}
        id={tid}
        onBack={onBack}
        onCreateOrder={(
          request: MerchantBackend.Template.UsingTemplateDetails,
        ) => {
          return createOrderFromTemplate(tid, request)
            .then((res) => onOrderCreated(res.data.order_id))
            .catch((error) => {
              setNotif({
                message: i18n.str`could not create order from template`,
                type: "ERROR",
                description: error.message,
              });
            });
        }}
      />
    </>
  );
}
