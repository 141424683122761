/*
 This file is part of GNU Taler
 (C) 2021-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import {
  ErrorType,
  HttpError,
  useTranslationContext,
} from "@gnu-taler/web-util/browser";
import { Fragment, h, VNode } from "preact";
import { useState } from "preact/hooks";
import { Loading } from "../../../../components/exception/loading.js";
import { NotificationCard } from "../../../../components/menu/index.js";
import { MerchantBackend } from "../../../../declaration.js";
import {
  useInstanceTemplates,
  useTemplateAPI,
} from "../../../../hooks/templates.js";
import { Notification } from "../../../../utils/types.js";
import { ListPage } from "./ListPage.js";
import { HttpStatusCode, TranslatedString } from "@gnu-taler/taler-util";
import { ConfirmModal } from "../../../../components/modal/index.js";
import { JumpToElementById } from "../../../../components/form/JumpToElementById.js";

interface Props {
  onUnauthorized: () => VNode;
  onLoadError: (error: HttpError<MerchantBackend.ErrorDetail>) => VNode;
  onNotFound: () => VNode;
  onCreate: () => void;
  onSelect: (id: string) => void;
  onNewOrder: (id: string) => void;
  onQR: (id: string) => void;
}

export default function ListTemplates({
  onUnauthorized,
  onLoadError,
  onCreate,
  onQR,
  onSelect,
  onNewOrder,
  onNotFound,
}: Props): VNode {
  const [position, setPosition] = useState<string | undefined>(undefined);
  const { i18n } = useTranslationContext();
  const [notif, setNotif] = useState<Notification | undefined>(undefined);
  const { deleteTemplate, testTemplateExist } = useTemplateAPI();
  const result = useInstanceTemplates({ position }, (id) => setPosition(id));
  const [deleting, setDeleting] =
    useState<MerchantBackend.Template.TemplateEntry | null>(null);

  if (result.loading) return <Loading />;
  if (!result.ok) {
    if (
      result.type === ErrorType.CLIENT &&
      result.status === HttpStatusCode.Unauthorized
    )
      return onUnauthorized();
    if (
      result.type === ErrorType.CLIENT &&
      result.status === HttpStatusCode.NotFound
    )
      return onNotFound();
    return onLoadError(result);
  }

  return (
    <section class="section is-main-section">
      <NotificationCard notification={notif} />

      <JumpToElementById
        testIfExist={testTemplateExist}
        onSelect={onSelect}
        description={i18n.str`jump to template with the given template ID`}
        placeholder={i18n.str`template id`}
      />

      <ListPage
        templates={result.data.templates}
        onLoadMoreBefore={
          result.isReachingStart ? result.loadMorePrev : undefined
        }
        onLoadMoreAfter={result.isReachingEnd ? result.loadMore : undefined}
        onCreate={onCreate}
        onSelect={(e) => {
          onSelect(e.template_id);
        }}
        onNewOrder={(e) => {
          onNewOrder(e.template_id);
        }}
        onQR={(e) => {
          onQR(e.template_id);
        }}
        onDelete={(e: MerchantBackend.Template.TemplateEntry) => {
          setDeleting(e)
        }
        }
      />

      {deleting && (
        <ConfirmModal
          label={`Delete template`}
          description={`Delete the template "${deleting.template_description}"`}
          danger
          active
          onCancel={() => setDeleting(null)}
          onConfirm={async (): Promise<void> => {
            try {
              await deleteTemplate(deleting.template_id);
              setNotif({
                message: i18n.str`Template "${deleting.template_description}" (ID: ${deleting.template_id}) has been deleted`,
                type: "SUCCESS",
              });
            } catch (error) {
              setNotif({
                message: i18n.str`Failed to delete template`,
                type: "ERROR",
                description: error instanceof Error ? error.message : undefined,
              });
            }
            setDeleting(null);
          }}
        >
          <p>
            If you delete the template <b>&quot;{deleting.template_description}&quot;</b> (ID:{" "}
            <b>{deleting.template_id}</b>) you may loose information
          </p>
          <p class="warning">
            Deleting an template <b>cannot be undone</b>.
          </p>
        </ConfirmModal>
      )}
    </section>
  );
}
