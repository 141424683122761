import { TranslatedString } from "@gnu-taler/taler-util";
import { VNode, h } from "preact";
import {
  LabelWithTooltipMaybeRequired
} from "./InputLine.js";

interface Props {
  label: TranslatedString;
  tooltip?: TranslatedString;
  help?: TranslatedString;
  before?: VNode;
  after?: VNode;
}

export function Caption({ before, after, label, tooltip, help }: Props): VNode {
  return (
    <div class="sm:col-span-6 flex">
      {before !== undefined && (
        <span class="pointer-events-none flex items-center pr-2">{before}</span>
      )}
      <LabelWithTooltipMaybeRequired label={label} tooltip={tooltip} />
      {after !== undefined && (
        <span class="pointer-events-none flex items-center pl-2">{after}</span>
      )}
      {help && (
        <p class="mt-2 text-sm text-gray-500" id="email-description">
          {help}
        </p>
      )}
    </div>
  );
}
