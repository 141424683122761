/*
 This file is part of GNU Taler
 (C) 2021-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { h, VNode } from "preact";
import { MerchantBackend } from "../../../../declaration.js";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { CardTable } from "./Table.js";

export interface Props {
  devices: MerchantBackend.OTP.OtpDeviceEntry[];
  onLoadMoreBefore?: () => void;
  onLoadMoreAfter?: () => void;
  onCreate: () => void;
  onDelete: (e: MerchantBackend.OTP.OtpDeviceEntry) => void;
  onSelect: (e: MerchantBackend.OTP.OtpDeviceEntry) => void;
}

export function ListPage({
  devices,
  onCreate,
  onDelete,
  onSelect,
  onLoadMoreBefore,
  onLoadMoreAfter,
}: Props): VNode {
  const form = { payto_uri: "" };

  const { i18n } = useTranslationContext();
  return (
    <section class="section is-main-section">
      <CardTable
        devices={devices.map((o) => ({
          ...o,
          id: String(o.otp_device_id),
        }))}
        onCreate={onCreate}
        onDelete={onDelete}
        onSelect={onSelect}
        onLoadMoreBefore={onLoadMoreBefore}
        hasMoreBefore={!onLoadMoreBefore}
        onLoadMoreAfter={onLoadMoreAfter}
        hasMoreAfter={!onLoadMoreAfter}
      />
    </section>
  );
}
