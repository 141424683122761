import { Fragment, VNode, h } from "preact"
import { Attention, GLOBAL_NOTIFICATION_TIMEOUT as GLOBAL_TOAST_TIMEOUT, useNotifications } from "../index.browser.js"

/**
 * Toasts should be considered when displaying these types of information to the user:
 * 
 * Low attention messages that do not require user action
 * Singular status updates
 * Confirmations
 * Information that does not need to be followed up
 * 
 * Do not use toasts if the information contains the following:
 * 
 * High attention and crtitical information
 * Time-sensitive information
 * Requires user action or input
 * Batch updates
 * 
 * @returns 
 */
export function ToastBanner(): VNode {
  const notifs = useNotifications()
  if (notifs.length === 0) return <Fragment />
  return <Fragment> {
    notifs.map(n => {
      switch (n.message.type) {
        case "error":
          return <Attention type="danger" title={n.message.title} onClose={() => {
            n.remove()
          }} timeout={GLOBAL_TOAST_TIMEOUT}>
            {n.message.description &&
              <div class="mt-2 text-sm text-red-700">
                {n.message.description}
              </div>
            }
          </Attention>
        case "info":
          return <Attention type="success" title={n.message.title} onClose={() => {
            n.remove();
          }} timeout={GLOBAL_TOAST_TIMEOUT} />
      }
    })}
  </Fragment>
}
