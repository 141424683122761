import { h as create, Fragment, VNode } from "preact";
import { Caption } from "./Caption.js";
import { FormProvider } from "./FormProvider.js";
import { Group } from "./Group.js";
import { InputAbsoluteTime } from "./InputAbsoluteTime.js";
import { InputAmount } from "./InputAmount.js";
import { InputArray } from "./InputArray.js";
import { InputChoiceHorizontal } from "./InputChoiceHorizontal.js";
import { InputChoiceStacked } from "./InputChoiceStacked.js";
import { InputFile } from "./InputFile.js";
import { InputInteger } from "./InputInteger.js";
import { InputLine } from "./InputLine.js";
import { InputSelectMultiple } from "./InputSelectMultiple.js";
import { InputSelectOne } from "./InputSelectOne.js";
import { InputText } from "./InputText.js";
import { InputTextArea } from "./InputTextArea.js";
import { InputToggle } from "./InputToggle.js";

/**
 * Constrain the type with the ui props
 */
type FieldType<T extends object = any, K extends keyof T = any> = {
  group: Parameters<typeof Group>[0];
  caption: Parameters<typeof Caption>[0];
  array: Parameters<typeof InputArray<T, K>>[0];
  file: Parameters<typeof InputFile<T, K>>[0];
  selectOne: Parameters<typeof InputSelectOne<T, K>>[0];
  selectMultiple: Parameters<typeof InputSelectMultiple<T, K>>[0];
  text: Parameters<typeof InputText<T, K>>[0];
  textArea: Parameters<typeof InputTextArea<T, K>>[0];
  choiceStacked: Parameters<typeof InputChoiceStacked<T, K>>[0];
  choiceHorizontal: Parameters<typeof InputChoiceHorizontal<T, K>>[0];
  absoluteTime: Parameters<typeof InputAbsoluteTime<T, K>>[0];
  integer: Parameters<typeof InputInteger<T, K>>[0];
  toggle: Parameters<typeof InputToggle<T, K>>[0];
  amount: Parameters<typeof InputAmount<T, K>>[0];
};

/**
 * List all the form fields so typescript can type-check the form instance
 */
export type UIFormField =
  | { type: "group"; props: FieldType["group"] }
  | { type: "caption"; props: FieldType["caption"] }
  | { type: "array"; props: FieldType["array"] }
  | { type: "file"; props: FieldType["file"] }
  | { type: "amount"; props: FieldType["amount"] }
  | { type: "selectOne"; props: FieldType["selectOne"] }
  | { type: "selectMultiple"; props: FieldType["selectMultiple"] }
  | { type: "text"; props: FieldType["text"] }
  | { type: "textArea"; props: FieldType["textArea"] }
  | { type: "choiceStacked"; props: FieldType["choiceStacked"] }
  | { type: "choiceHorizontal"; props: FieldType["choiceHorizontal"] }
  | { type: "integer"; props: FieldType["integer"] }
  | { type: "toggle"; props: FieldType["toggle"] }
  | { type: "absoluteTime"; props: FieldType["absoluteTime"] };

type FieldComponentFunction<key extends keyof FieldType> = (
  props: FieldType[key],
) => VNode;

type UIFormFieldMap = {
  [key in keyof FieldType]: FieldComponentFunction<key>;
};

/**
 * Maps input type with component implementation
 */
const UIFormConfiguration: UIFormFieldMap = {
  group: Group,
  caption: Caption,
  //@ts-ignore
  array: InputArray,
  text: InputText,
  //@ts-ignore
  file: InputFile,
  textArea: InputTextArea,
  //@ts-ignore
  absoluteTime: InputAbsoluteTime,
  //@ts-ignore
  choiceStacked: InputChoiceStacked,
  //@ts-ignore
  choiceHorizontal: InputChoiceHorizontal,
  integer: InputInteger,
  //@ts-ignore
  selectOne: InputSelectOne,
  //@ts-ignore
  selectMultiple: InputSelectMultiple,
  //@ts-ignore
  toggle: InputToggle,
  //@ts-ignore
  amount: InputAmount,
};

export function RenderAllFieldsByUiConfig({
  fields,
}: {
  fields: UIFormField[];
}): VNode {
  return create(
    Fragment,
    {},
    fields.map((field, i) => {
      const Component = UIFormConfiguration[
        field.type
      ] as FieldComponentFunction<any>;
      return Component(field.props);
    }),
  );
}

type FormSet<T extends object> = {
  Provider: typeof FormProvider<T>;
  InputLine: <K extends keyof T>() => typeof InputLine<T, K>;
  InputChoiceHorizontal: <K extends keyof T>() => typeof InputChoiceHorizontal<T, K>;
};

/**
 * Helper function that created a typed object.
 * 
 * @returns 
 */
export function createNewForm<T extends object>() {
  const res: FormSet<T> = {
    Provider: FormProvider,
    InputLine: () => InputLine,
    InputChoiceHorizontal: () => InputChoiceHorizontal,
  };
  return {
    Provider: res.Provider,
    InputLine: res.InputLine(),
    InputChoiceHorizontal: res.InputChoiceHorizontal(),
  };
}
