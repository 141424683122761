/*
 This file is part of GNU Taler
 (C) 2021-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */
import {
  HttpResponse,
  HttpResponseOk,
  HttpResponsePaginated,
  RequestError,
} from "@gnu-taler/web-util/browser";
import { useEffect, useState } from "preact/hooks";
import { MerchantBackend } from "../declaration.js";
import { MAX_RESULT_SIZE, PAGE_SIZE } from "../utils/constants.js";
import { useBackendInstanceRequest, useMatchMutate } from "./backend.js";

// FIX default import https://github.com/microsoft/TypeScript/issues/49189
import _useSWR, { SWRHook } from "swr";
const useSWR = _useSWR as unknown as SWRHook;

export function useTemplateAPI(): TemplateAPI {
  const mutateAll = useMatchMutate();
  const { request } = useBackendInstanceRequest();

  const createTemplate = async (
    data: MerchantBackend.Template.TemplateAddDetails,
  ): Promise<HttpResponseOk<void>> => {
    const res = await request<void>(`/private/templates`, {
      method: "POST",
      data,
    });
    await mutateAll(/.*private\/templates.*/);
    return res;
  };

  const updateTemplate = async (
    templateId: string,
    data: MerchantBackend.Template.TemplatePatchDetails,
  ): Promise<HttpResponseOk<void>> => {
    const res = await request<void>(`/private/templates/${templateId}`, {
      method: "PATCH",
      data,
    });
    await mutateAll(/.*private\/templates.*/);
    return res;
  };

  const deleteTemplate = async (
    templateId: string,
  ): Promise<HttpResponseOk<void>> => {
    const res = await request<void>(`/private/templates/${templateId}`, {
      method: "DELETE",
    });
    await mutateAll(/.*private\/templates.*/);
    return res;
  };

  const createOrderFromTemplate = async (
    templateId: string,
    data: MerchantBackend.Template.UsingTemplateDetails,
  ): Promise<
    HttpResponseOk<MerchantBackend.Template.UsingTemplateResponse>
  > => {
    const res = await request<MerchantBackend.Template.UsingTemplateResponse>(
      `/templates/${templateId}`,
      {
        method: "POST",
        data,
      },
    );
    await mutateAll(/.*private\/templates.*/);
    return res;
  };

  const testTemplateExist = async (
    templateId: string,
  ): Promise<HttpResponseOk<void>> => {
    const res = await request<void>(`/private/templates/${templateId}`, { method: "GET", });
    return res;
  };


  return {
    createTemplate,
    updateTemplate,
    deleteTemplate,
    testTemplateExist,
    createOrderFromTemplate,
  };
}

export interface TemplateAPI {
  createTemplate: (
    data: MerchantBackend.Template.TemplateAddDetails,
  ) => Promise<HttpResponseOk<void>>;
  updateTemplate: (
    id: string,
    data: MerchantBackend.Template.TemplatePatchDetails,
  ) => Promise<HttpResponseOk<void>>;
  testTemplateExist: (
    id: string
  ) => Promise<HttpResponseOk<void>>;
  deleteTemplate: (id: string) => Promise<HttpResponseOk<void>>;
  createOrderFromTemplate: (
    id: string,
    data: MerchantBackend.Template.UsingTemplateDetails,
  ) => Promise<HttpResponseOk<MerchantBackend.Template.UsingTemplateResponse>>;
}

export interface InstanceTemplateFilter {
  //FIXME: add filter to the template list
  position?: string;
}

export function useInstanceTemplates(
  args?: InstanceTemplateFilter,
  updatePosition?: (id: string) => void,
): HttpResponsePaginated<
  MerchantBackend.Template.TemplateSummaryResponse,
  MerchantBackend.ErrorDetail
> {
  const { templateFetcher } = useBackendInstanceRequest();

  const [pageBefore, setPageBefore] = useState(1);
  const [pageAfter, setPageAfter] = useState(1);

  const totalAfter = pageAfter * PAGE_SIZE;
  const totalBefore = args?.position ? pageBefore * PAGE_SIZE : 0;

  /**
   * FIXME: this can be cleaned up a little
   *
   * the logic of double query should be inside the orderFetch so from the hook perspective and cache
   * is just one query and one error status
   */
  const {
    data: beforeData,
    error: beforeError,
    isValidating: loadingBefore,
  } = useSWR<
    HttpResponseOk<MerchantBackend.Template.TemplateSummaryResponse>,
    RequestError<MerchantBackend.ErrorDetail>>(
      [
        `/private/templates`,
        args?.position,
        totalBefore,
      ],
      templateFetcher,
    );
  const {
    data: afterData,
    error: afterError,
    isValidating: loadingAfter,
  } = useSWR<
    HttpResponseOk<MerchantBackend.Template.TemplateSummaryResponse>,
    RequestError<MerchantBackend.ErrorDetail>
  >([`/private/templates`, args?.position, -totalAfter], templateFetcher);

  //this will save last result
  const [lastBefore, setLastBefore] = useState<
    HttpResponse<
      MerchantBackend.Template.TemplateSummaryResponse,
      MerchantBackend.ErrorDetail
    >
  >({ loading: true });

  const [lastAfter, setLastAfter] = useState<
    HttpResponse<
      MerchantBackend.Template.TemplateSummaryResponse,
      MerchantBackend.ErrorDetail
    >
  >({ loading: true });
  useEffect(() => {
    if (afterData) setLastAfter(afterData);
    if (beforeData) setLastBefore(beforeData);
  }, [afterData, beforeData]);

  if (beforeError) return beforeError.cause;
  if (afterError) return afterError.cause;

  // if the query returns less that we ask, then we have reach the end or beginning
  const isReachingEnd =
    afterData && afterData.data.templates.length < totalAfter;
  const isReachingStart = args?.position === undefined
    ||
    (beforeData && beforeData.data.templates.length < totalBefore);

  const pagination = {
    isReachingEnd,
    isReachingStart,
    loadMore: () => {
      if (!afterData || isReachingEnd) return;
      if (afterData.data.templates.length < MAX_RESULT_SIZE) {
        setPageAfter(pageAfter + 1);
      } else {
        const from = `${afterData.data.templates[afterData.data.templates.length - 1]
          .template_id
          }`;
        if (from && updatePosition) updatePosition(from);
      }
    },
    loadMorePrev: () => {
      if (!beforeData || isReachingStart) return;
      if (beforeData.data.templates.length < MAX_RESULT_SIZE) {
        setPageBefore(pageBefore + 1);
      } else if (beforeData) {
        const from = `${beforeData.data.templates[beforeData.data.templates.length - 1]
          .template_id
          }`;
        if (from && updatePosition) updatePosition(from);
      }
    },
  };

  // const templates = !afterData ? [] : (afterData || lastAfter).data.templates;
  const templates =
    !beforeData || !afterData
      ? []
      : (beforeData || lastBefore).data.templates
        .slice()
        .reverse()
        .concat((afterData || lastAfter).data.templates);
  if (loadingAfter || loadingBefore)
    return { loading: true, data: { templates } };
  if (beforeData && afterData) {
    return { ok: true, data: { templates }, ...pagination };
  }
  return { loading: true };
}

export function useTemplateDetails(
  templateId: string,
): HttpResponse<
  MerchantBackend.Template.TemplateDetails,
  MerchantBackend.ErrorDetail
> {
  const { templateFetcher } = useBackendInstanceRequest();

  const { data, error, isValidating } = useSWR<
    HttpResponseOk<MerchantBackend.Template.TemplateDetails>,
    RequestError<MerchantBackend.ErrorDetail>
  >([`/private/templates/${templateId}`], templateFetcher, {
    refreshInterval: 0,
    refreshWhenHidden: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
  });

  if (isValidating) return { loading: true, data: data?.data };
  if (data) {
    return data;
  }
  if (error) return error.cause;
  return { loading: true };
}
