/*
 This file is part of GNU Taler
 (C) 2021-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import {
  ErrorType,
  HttpError,
  useTranslationContext,
} from "@gnu-taler/web-util/browser";
import { Fragment, h, VNode } from "preact";
import { useState } from "preact/hooks";
import { Loading } from "../../../../components/exception/loading.js";
import { NotificationCard } from "../../../../components/menu/index.js";
import { MerchantBackend, WithId } from "../../../../declaration.js";
import {
  useTemplateAPI,
  useTemplateDetails,
} from "../../../../hooks/templates.js";
import { Notification } from "../../../../utils/types.js";
import { UpdatePage } from "./UpdatePage.js";
import { HttpStatusCode } from "@gnu-taler/taler-util";

export type Entity = MerchantBackend.Template.TemplatePatchDetails & WithId;

interface Props {
  onBack?: () => void;
  onConfirm: () => void;
  onUnauthorized: () => VNode;
  onNotFound: () => VNode;
  onLoadError: (e: HttpError<MerchantBackend.ErrorDetail>) => VNode;
  tid: string;
}
export default function UpdateTemplate({
  tid,
  onConfirm,
  onBack,
  onUnauthorized,
  onNotFound,
  onLoadError,
}: Props): VNode {
  const { updateTemplate } = useTemplateAPI();
  const result = useTemplateDetails(tid);
  const [notif, setNotif] = useState<Notification | undefined>(undefined);

  const { i18n } = useTranslationContext();

  if (result.loading) return <Loading />;
  if (!result.ok) {
    if (
      result.type === ErrorType.CLIENT &&
      result.status === HttpStatusCode.Unauthorized
    )
      return onUnauthorized();
    if (
      result.type === ErrorType.CLIENT &&
      result.status === HttpStatusCode.NotFound
    )
      return onNotFound();
    return onLoadError(result);
  }

  return (
    <Fragment>
      <NotificationCard notification={notif} />
      <UpdatePage
        template={{ ...result.data }}
        onBack={onBack}
        onUpdate={(data) => {
          return updateTemplate(tid, data)
            .then(onConfirm)
            .catch((error) => {
              setNotif({
                message: i18n.str`could not update template`,
                type: "ERROR",
                description: error.message,
              });
            });
        }}
      />
    </Fragment>
  );
}
