/*
 This file is part of GNU Taler
 (C) 2021-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import {
  ErrorType,
  HttpError,
  useTranslationContext,
} from "@gnu-taler/web-util/browser";
import { h, VNode } from "preact";
import { useState } from "preact/hooks";
import { Loading } from "../../../../components/exception/loading.js";
import { NotificationCard } from "../../../../components/menu/index.js";
import { MerchantBackend, WithId } from "../../../../declaration.js";
import {
  useInstanceProducts,
  useProductAPI,
} from "../../../../hooks/product.js";
import { Notification } from "../../../../utils/types.js";
import { CardTable } from "./Table.js";
import { HttpStatusCode } from "@gnu-taler/taler-util";
import { ConfirmModal, DeleteModal } from "../../../../components/modal/index.js";
import { JumpToElementById } from "../../../../components/form/JumpToElementById.js";

interface Props {
  onUnauthorized: () => VNode;
  onNotFound: () => VNode;
  onCreate: () => void;
  onSelect: (id: string) => void;
  onLoadError: (e: HttpError<MerchantBackend.ErrorDetail>) => VNode;
}
export default function ProductList({
  onUnauthorized,
  onLoadError,
  onCreate,
  onSelect,
  onNotFound,
}: Props): VNode {
  const result = useInstanceProducts();
  const { deleteProduct, updateProduct, getProduct } = useProductAPI();
  const [deleting, setDeleting] =
    useState<MerchantBackend.Products.ProductDetail & WithId | null>(null);
  const [notif, setNotif] = useState<Notification | undefined>(undefined);

  const { i18n } = useTranslationContext();

  if (result.loading) return <Loading />;
  if (!result.ok) {
    if (
      result.type === ErrorType.CLIENT &&
      result.status === HttpStatusCode.Unauthorized
    )
      return onUnauthorized();
    if (
      result.type === ErrorType.CLIENT &&
      result.status === HttpStatusCode.NotFound
    )
      return onNotFound();
    return onLoadError(result);
  }

  return (
    <section class="section is-main-section">
      <NotificationCard notification={notif} />

      <JumpToElementById
        testIfExist={getProduct} 
        onSelect={onSelect}
        description={i18n.str`jump to product with the given product ID`}
        placeholder={i18n.str`product id`}
      />

      <CardTable
        instances={result.data}
        onCreate={onCreate}
        onUpdate={(id, prod) =>
          updateProduct(id, prod)
            .then(() =>
              setNotif({
                message: i18n.str`product updated successfully`,
                type: "SUCCESS",
              }),
            )
            .catch((error) =>
              setNotif({
                message: i18n.str`could not update the product`,
                type: "ERROR",
                description: error.message,
              }),
            )
        }
        onSelect={(product) => onSelect(product.id)}
        onDelete={(prod: MerchantBackend.Products.ProductDetail & WithId) =>
          setDeleting(prod)
        }
      />

      {deleting && (
        <ConfirmModal
          label={`Delete product`}
          description={`Delete the product "${deleting.description}"`}
          danger
          active
          onCancel={() => setDeleting(null)}
          onConfirm={async (): Promise<void> => {
            try {
              await deleteProduct(deleting.id);
              setNotif({
                message: i18n.str`Product "${deleting.description}" (ID: ${deleting.id}) has been deleted`,
                type: "SUCCESS",
              });
            } catch (error) {
              setNotif({
                message: i18n.str`Failed to delete product`,
                type: "ERROR",
                description: error instanceof Error ? error.message : undefined,
              });
            }
            setDeleting(null);
          }}
        >
          <p>
            If you delete the product named <b>&quot;{deleting.description}&quot;</b> (ID:{" "}
            <b>{deleting.id}</b>), the stock and related information will be lost
          </p>
          <p class="warning">
            Deleting an product <b>cannot be undone</b>.
          </p>
        </ConfirmModal>
      )}
    </section>
  );
}
