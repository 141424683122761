import { TranslatedString } from "@gnu-taler/taler-util";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { h, VNode } from "preact";
import { useState } from "preact/hooks";

export function JumpToElementById({ testIfExist, onSelect, placeholder, description }: { placeholder: TranslatedString, description: TranslatedString, testIfExist: (id: string) => Promise<any>, onSelect: (id: string) => void }): VNode {
  const { i18n } = useTranslationContext()

  const [error, setError] = useState<string | undefined>(
    undefined,
  );

  const [id, setId] = useState<string>()
  async function check(currentId: string | undefined): Promise<void> {
    if (!currentId) {
      setError(i18n.str`missing id`);
      return;
    }
    try {
      await testIfExist(currentId);
      onSelect(currentId);
      setError(undefined);
    } catch {
      setError(i18n.str`not found`);
    }
  }

  return <div class="level">
    <div class="level-left">
      <div class="level-item">
        <div class="field has-addons">
          <div class="control">
            <input
              class={error ? "input is-danger" : "input"}
              type="text"
              value={id ?? ""}
              onChange={(e) => setId(e.currentTarget.value)}
              placeholder={placeholder}
            />
            {error && <p class="help is-danger">{error}</p>}
          </div>
          <span
            class="has-tooltip-bottom"
            data-tooltip={description}
          >
            <button
              class="button"
              onClick={(e) => check(id)}
            >
              <span class="icon">
                <i class="mdi mdi-arrow-right" />
              </span>
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
}
