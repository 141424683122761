import { TranslatedString } from "@gnu-taler/taler-util";
import { VNode, h } from "preact";
import { LabelWithTooltipMaybeRequired } from "./InputLine.js";
import { RenderAllFieldsByUiConfig, UIFormField } from "./forms.js";

interface Props {
  before?: TranslatedString;
  after?: TranslatedString;
  tooltipBefore?: TranslatedString;
  tooltipAfter?: TranslatedString;
  fields: UIFormField[];
}

export function Group({
  before,
  after,
  tooltipAfter,
  tooltipBefore,
  fields,
}: Props): VNode {
  return (
    <div class="sm:col-span-6 p-4 rounded-lg border-r-2 border-2 bg-gray-50">
      <div class="pb-4">
        {before && (
          <LabelWithTooltipMaybeRequired
            label={before}
            tooltip={tooltipBefore}
          />
        )}
      </div>
      <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
        <RenderAllFieldsByUiConfig fields={fields} />
      </div>
      <div class="pt-4">
        {after && (
          <LabelWithTooltipMaybeRequired label={after} tooltip={tooltipAfter} />
        )}
      </div>
    </div>
  );
}
