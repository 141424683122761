import { useContext, useState } from "preact/compat";
import { FieldUIOptions, FormContext } from "./FormProvider.js";

export interface InputFieldHandler<Type> {
  value: Type;
  onChange: (s: Type) => void;
  state: FieldUIOptions;
  isDirty: boolean;
}

export function useField<T extends object, K extends keyof T>(
  name: K,
): InputFieldHandler<T[K]> {
  const {
    value: formValue,
    computeFormState,
    onUpdate: notifyUpdate,
    readOnly: readOnlyForm,
  } = useContext(FormContext);

  type P = typeof name;
  type V = T[P];
  const formState = computeFormState ? computeFormState(formValue.current) : {};

  const fieldValue = readField(formValue.current, String(name)) as V;
  // console.log("USE FIELD", String(name), formValue.current, fieldValue);
  const [currentValue, setCurrentValue] = useState<any | undefined>(fieldValue);
  const fieldState =
    readField<Partial<FieldUIOptions>>(formState, String(name)) ?? {};

  //compute default state
  const state = {
    disabled: readOnlyForm ? true : (fieldState.disabled ?? false),
    hidden: fieldState.hidden ?? false,
    error: fieldState.error,
    help: fieldState.help,
    elements: "elements" in fieldState ? fieldState.elements ?? [] : [],
  };

  function onChange(value: V): void {
    setCurrentValue(value);
    formValue.current = setValueDeeper(
      formValue.current,
      String(name).split("."),
      value,
    );
    if (notifyUpdate) {
      notifyUpdate(formValue.current);
    }
  }

  return {
    value: fieldValue,
    onChange,
    isDirty: currentValue !== undefined,
    state,
  };
}

/**
 * read the field of an object an support accessing it using '.'
 *
 * @param object
 * @param name
 * @returns
 */
function readField<T>(
  object: any,
  name: string,
  debug?: boolean,
): T | undefined {
  return name.split(".").reduce((prev, current) => {
    if (debug) {
      console.log(
        "READ",
        name,
        prev,
        current,
        prev ? prev[current] : undefined,
      );
    }
    return prev ? prev[current] : undefined;
  }, object);
}

function setValueDeeper(object: any, names: string[], value: any): any {
  if (names.length === 0) return value;
  const [head, ...rest] = names;
  if (object === undefined) {
    return { [head]: setValueDeeper({}, rest, value) };
  }
  return { ...object, [head]: setValueDeeper(object[head] ?? {}, rest, value) };
}
