/*
 This file is part of GNU Taler
 (C) 2021-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import {
  AmountString,
  Amounts,
  Duration,
  assertUnreachable
} from "@gnu-taler/taler-util";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { useState } from "preact/hooks";
import { AsyncButton } from "../../../../components/exception/AsyncButton.js";
import {
  FormErrors,
  FormProvider,
} from "../../../../components/form/FormProvider.js";
import { Input } from "../../../../components/form/Input.js";
import { InputCurrency } from "../../../../components/form/InputCurrency.js";
import { InputDuration } from "../../../../components/form/InputDuration.js";
import { InputNumber } from "../../../../components/form/InputNumber.js";
import { InputTab } from "../../../../components/form/InputTab.js";
import { InputWithAddon } from "../../../../components/form/InputWithAddon.js";
import { useBackendContext } from "../../../../context/backend.js";
import { MerchantBackend } from "../../../../declaration.js";
import { InputSearchOnList } from "../../../../components/form/InputSearchOnList.js";
import { useInstanceOtpDevices } from "../../../../hooks/otp.js";

enum Steps {
  BOTH_FIXED,
  FIXED_PRICE,
  FIXED_SUMMARY,
  NON_FIXED,
}

type Entity = {
  description?: string,
  otpId?: string | null,
  summary?: string,
  amount?: AmountString,
  minimum_age?: number,
  pay_duration?: Duration,
};

interface Props {
  onUpdate: (d: MerchantBackend.Template.TemplatePatchDetails) => Promise<void>;
  onBack?: () => void;
  template: MerchantBackend.Template.TemplateDetails;
}

export function UpdatePage({ template, onUpdate, onBack }: Props): VNode {
  const { i18n } = useTranslationContext();
  const { url: backendURL } = useBackendContext()

  const intialStep =
    template.template_contract.amount === undefined && template.template_contract.summary === undefined
      ? Steps.NON_FIXED
      : template.template_contract.summary === undefined
        ? Steps.FIXED_PRICE
        : template.template_contract.amount === undefined
          ? Steps.FIXED_SUMMARY
          : Steps.BOTH_FIXED;

  const [state, setState] = useState<Partial<Entity & { type: Steps }>>({
    amount: template.template_contract.amount as AmountString | undefined,
    description: template.template_description,
    minimum_age: template.template_contract.minimum_age,
    otpId: template.otp_id,
    pay_duration: template.template_contract.pay_duration ? Duration.fromTalerProtocolDuration(template.template_contract.pay_duration) : undefined,
    summary: template.template_contract.summary,
    type: intialStep,
  });
  const devices = useInstanceOtpDevices()
  const deviceList = !devices.ok ? [] : devices.data.otp_devices

  const parsedPrice = !state.amount
    ? undefined
    : Amounts.parse(state.amount);

  const errors: FormErrors<Entity> = {
    description: !state.description
      ? i18n.str`should not be empty`
      : undefined,
    amount: !(state.type === Steps.FIXED_PRICE || state.type === Steps.BOTH_FIXED)
      ? undefined
      : !state.amount
        ? i18n.str`required`
        : !parsedPrice
          ? i18n.str`not valid`
          : Amounts.isZero(parsedPrice)
            ? i18n.str`must be greater than 0`
            : undefined,
    summary: !(state.type === Steps.FIXED_SUMMARY || state.type === Steps.BOTH_FIXED)
      ? undefined
      : !state.summary
        ? i18n.str`required`
        : undefined,
    minimum_age:
      state.minimum_age && state.minimum_age < 0
        ? i18n.str`should be greater that 0`
        : undefined,
    pay_duration: !state.pay_duration
      ? i18n.str`can't be empty`
      : state.pay_duration.d_ms === "forever"
        ? undefined
        : state.pay_duration.d_ms < 1000 // less than one second
          ? i18n.str`to short`
          : undefined,
  };

  const hasErrors = Object.keys(errors).some(
    (k) => (errors as any)[k] !== undefined,
  );

  const submitForm = () => {
    if (hasErrors || state.type === undefined) return Promise.reject();
    switch (state.type) {
      case Steps.FIXED_PRICE: return onUpdate({
        template_description: state.description!,
        template_contract: {
          minimum_age: state.minimum_age!,
          pay_duration: Duration.toTalerProtocolDuration(state.pay_duration!),
          amount: state.amount!,
          // summary: state.summary,
        },
        otp_id: state.otpId!
      })
      case Steps.FIXED_SUMMARY: return onUpdate({
        template_description: state.description!,
        template_contract: {
          minimum_age: state.minimum_age!,
          pay_duration: Duration.toTalerProtocolDuration(state.pay_duration!),
          // amount: state.amount!,
          summary: state.summary,
        },
        otp_id: state.otpId!,
      })
      case Steps.NON_FIXED: return onUpdate({
        template_description: state.description!,
        template_contract: {
          minimum_age: state.minimum_age!,
          pay_duration: Duration.toTalerProtocolDuration(state.pay_duration!),
          // amount: state.amount!,
          // summary: state.summary,
        },
        otp_id: state.otpId!,
      })
      case Steps.BOTH_FIXED: return onUpdate({
        template_description: state.description!,
        template_contract: {
          minimum_age: state.minimum_age!,
          pay_duration: Duration.toTalerProtocolDuration(state.pay_duration!),
          amount: state.amount!,
          summary: state.summary,
        },
        otp_id: state.otpId!,
      })
      default: assertUnreachable(state.type)
    }
  };


  return (
    <div>
      <section class="section">
        <section class="hero is-hero-bar">
          <div class="hero-body">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <span class="is-size-4">
                    {backendURL}/templates/{template.otp_id}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr />

        <section class="section is-main-section">
          <div class="columns">
            <div class="column is-four-fifths">
              <FormProvider
                object={state}
                valueHandler={setState}
                errors={errors}
              >

                <Input<Entity>
                  name="description"
                  label={i18n.str`Description`}
                  help=""
                  tooltip={i18n.str`Describe what this template stands for`}
                />
                <InputTab
                  name="type"
                  label={i18n.str`Type`}
                  help={(() => {
                    switch (state.type) {
                      case Steps.NON_FIXED: return i18n.str`User will be able to input price and summary before payment.`
                      case Steps.FIXED_PRICE: return i18n.str`User will be able to add a summary before payment.`
                      case Steps.FIXED_SUMMARY: return i18n.str`User will be able to set the price before payment.`
                      case Steps.BOTH_FIXED: return i18n.str`User will not be able to change the price or the summary.`
                    }
                  })()}
                  tooltip={i18n.str`Define what the user be allowed to modify`}
                  values={[
                    Steps.NON_FIXED,
                    Steps.FIXED_PRICE,
                    Steps.FIXED_SUMMARY,
                    Steps.BOTH_FIXED,
                  ]}
                  toStr={(v: Steps): string => {
                    switch (v) {
                      case Steps.NON_FIXED: return i18n.str`Simple`
                      case Steps.FIXED_PRICE: return i18n.str`With price`
                      case Steps.FIXED_SUMMARY: return i18n.str`With summary`
                      case Steps.BOTH_FIXED: return i18n.str`With price and summary`
                    }
                  }}
                />
                {state.type === Steps.BOTH_FIXED || state.type === Steps.FIXED_SUMMARY ?
                  <Input<Entity>
                    name="summary"
                    inputType="multiline"
                    label={i18n.str`Fixed summary`}
                    tooltip={i18n.str`If specified, this template will create order with the same summary`}
                  />
                  : undefined}
                {state.type === Steps.BOTH_FIXED || state.type === Steps.FIXED_PRICE ?
                  <InputCurrency<Entity>
                    name="amount"
                    label={i18n.str`Fixed price`}
                    tooltip={i18n.str`If specified, this template will create order with the same price`}
                  />
                  : undefined}
                <InputNumber<Entity>
                  name="minimum_age"
                  label={i18n.str`Minimum age`}
                  help=""
                  tooltip={i18n.str`Is this contract restricted to some age?`}
                />
                <InputDuration<Entity>
                  name="pay_duration"
                  label={i18n.str`Payment timeout`}
                  help=""
                  tooltip={i18n.str`How much time has the customer to complete the payment once the order was created.`}
                />
                <Input<Entity>
                  name="otpId"
                  label={i18n.str`OTP device`}
                  readonly
                  side={<button
                    class="button is-danger"
                    data-tooltip={i18n.str`remove otp device for this template`}
                    onClick={(): void => {
                      setState((v) => ({ ...v, otpId: null }));
                    }}
                  >
                    <span>
                      <i18n.Translate>remove</i18n.Translate>
                    </span>
                  </button>}
                  tooltip={i18n.str`Use to verify transaction in offline mode.`}
                />
                <InputSearchOnList
                  label={i18n.str`Search device`}
                  onChange={(p) => setState((v) => ({ ...v, otpId: p?.id }))}
                  list={deviceList.map(e => ({
                    description: e.device_description,
                    id: e.otp_device_id
                  }))}
                />
              </FormProvider>

              <div class="buttons is-right mt-5">
                {onBack && (
                  <button class="button" onClick={onBack}>
                    <i18n.Translate>Cancel</i18n.Translate>
                  </button>
                )}
                <AsyncButton
                  disabled={hasErrors}
                  data-tooltip={
                    hasErrors
                      ? i18n.str`Need to complete marked fields`
                      : "confirm operation"
                  }
                  onClick={submitForm}
                >
                  <i18n.Translate>Confirm</i18n.Translate>
                </AsyncButton>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}
