/*
 This file is part of GNU Taler
 (C) 2021-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, h, VNode } from "preact";
import { useState } from "preact/hooks";
import { AsyncButton } from "../../../../components/exception/AsyncButton.js";
import {
  FormErrors,
  FormProvider,
} from "../../../../components/form/FormProvider.js";
import { Input } from "../../../../components/form/Input.js";
import { MerchantBackend, WithId } from "../../../../declaration.js";
import { InputSelector } from "../../../../components/form/InputSelector.js";
import { InputPaytoForm } from "../../../../components/form/InputPaytoForm.js";
import { undefinedIfEmpty } from "../../../../utils/table.js";

type Entity = MerchantBackend.BankAccounts.BankAccountEntry
  & WithId;

const accountAuthType = ["unedit", "none", "basic"];
interface Props {
  onUpdate: (d: MerchantBackend.BankAccounts.AccountPatchDetails) => Promise<void>;
  onBack?: () => void;
  account: Entity;
}


export function UpdatePage({ account, onUpdate, onBack }: Props): VNode {
  const { i18n } = useTranslationContext();

  const [state, setState] = useState<Partial<MerchantBackend.BankAccounts.AccountPatchDetails>>(account);

  // @ts-expect-error "unedit" is fine since is part of the accountAuthType values
  if (state.credit_facade_credentials?.type === "unedit") {
    // we use this to set creds to undefined but server don't get this type
    state.credit_facade_credentials = undefined
  }

  const errors: FormErrors<MerchantBackend.BankAccounts.AccountPatchDetails> = {
    credit_facade_url: !state.credit_facade_url ? undefined : !isValidURL(state.credit_facade_url) ? i18n.str`invalid url` : undefined,
    credit_facade_credentials: undefinedIfEmpty({

      username: state.credit_facade_credentials?.type !== "basic" ? undefined
        : !state.credit_facade_credentials.username ? i18n.str`required` : undefined,

      password: state.credit_facade_credentials?.type !== "basic" ? undefined
        : !state.credit_facade_credentials.password ? i18n.str`required` : undefined,

      repeatPassword: state.credit_facade_credentials?.type !== "basic" ? undefined
        : !(state.credit_facade_credentials as any).repeatPassword ? i18n.str`required` :
          (state.credit_facade_credentials as any).repeatPassword !== state.credit_facade_credentials.password ? i18n.str`doesn't match`
            : undefined,
    }),
  };

  const hasErrors = Object.keys(errors).some(
    (k) => (errors as any)[k] !== undefined,
  );

  const submitForm = () => {
    if (hasErrors) return Promise.reject();

    const credit_facade_url = !state.credit_facade_url ? undefined : new URL("/", state.credit_facade_url).href

    const credit_facade_credentials: MerchantBackend.BankAccounts.FacadeCredentials | undefined =
      credit_facade_url == undefined || state.credit_facade_credentials === undefined ? undefined :
        state.credit_facade_credentials.type === "basic" ? {
          type: "basic",
          password: state.credit_facade_credentials.password,
          username: state.credit_facade_credentials.username,
        } : {
          type: "none"
        };

    return onUpdate({ credit_facade_credentials, credit_facade_url });
  };

  return (
    <div>
      <section class="section">
        <section class="hero is-hero-bar">
          <div class="hero-body">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <span class="is-size-4">
                    Account: <b>{account.id.substring(0, 8)}...</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr />

        <section class="section is-main-section">
          <div class="columns">
            <div class="column is-four-fifths">
              <FormProvider
                object={state}
                valueHandler={setState}
                errors={errors}
              >
                <InputPaytoForm<Entity>
                  name="payto_uri"
                  label={i18n.str`Account`}
                  readonly
                />
                <Input<Entity>
                  name="credit_facade_url"
                  label={i18n.str`Account info URL`}
                  help="https://bank.com"
                  expand
                  tooltip={i18n.str`From where the merchant can download information about incoming wire transfers to this account`}
                />
                <InputSelector
                  name="credit_facade_credentials.type"
                  label={i18n.str`Auth type`}
                  tooltip={i18n.str`Choose the authentication type for the account info URL`}
                  values={accountAuthType}
                  toStr={(str) => {
                    if (str === "none") return "Without authentication";
                    if (str === "basic") return "With authentication";
                    return "Do not change"
                  }}
                />
                {state.credit_facade_credentials?.type === "basic" ? (
                  <Fragment>
                    <Input
                      name="credit_facade_credentials.username"
                      label={i18n.str`Username`}
                      tooltip={i18n.str`Username to access the account information.`}
                    />
                    <Input
                      name="credit_facade_credentials.password"
                      inputType="password"
                      label={i18n.str`Password`}
                      tooltip={i18n.str`Password to access the account information.`}
                    />
                    <Input
                      name="credit_facade_credentials.repeatPassword"
                      inputType="password"
                      label={i18n.str`Repeat password`}
                    />
                  </Fragment>
                ) : undefined}
              </FormProvider>

              <div class="buttons is-right mt-5">
                {onBack && (
                  <button class="button" onClick={onBack}>
                    <i18n.Translate>Cancel</i18n.Translate>
                  </button>
                )}
                <AsyncButton
                  disabled={hasErrors}
                  data-tooltip={
                    hasErrors
                      ? i18n.str`Need to complete marked fields`
                      : "confirm operation"
                  }
                  onClick={submitForm}
                >
                  <i18n.Translate>Confirm</i18n.Translate>
                </AsyncButton>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}

function isValidURL(s: string): boolean {
  try {
    const u = new URL("/", s)
    return true;
  } catch (e) {
    return false;
  }
}
