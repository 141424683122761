import { h, Fragment, VNode } from "preact";
import { Attention } from "./Attention.js";
import { Notification } from "../index.browser.js";

export function LocalNotificationBanner({ notification, showDebug }: { notification?: Notification, showDebug?: boolean }): VNode {
  if (!notification) return <Fragment />
  switch (notification.message.type) {
    case "error":
      return <div class="relative">
        <div class="fixed top-0 left-0 right-0 z-20 w-full p-4">
          <Attention type="danger" title={notification.message.title} onClose={() => {
            notification.remove()
          }}>
            {notification.message.description &&
              <div class="mt-2 text-sm text-red-700">
                {notification.message.description}
              </div>
            }
            {showDebug && <pre class="whitespace-break-spaces ">
              {notification.message.debug}
            </pre>}
          </Attention>
        </div>
      </div>
    case "info":
      return <div class="relative">
        <div class="fixed top-0 left-0 right-0 z-20 w-full p-4">
          <Attention type="success" title={notification.message.title} onClose={() => {
            notification.remove();
          }} /></div></div>
  }
}

